.form-add-cost {
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 350px;
  margin: 20px auto;
}
.form-add-cost input {
  padding: 10px;
  margin: 10px 0;
}
.form-add-cost button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px;
  width: 100px;
  margin: 0 auto;
  border-radius: 0 auto;
  border-radius: 10px;
  cursor: pointer;
}
.dates {
  display: flex;
  align-items: center;
}
.titleToRegistrationForm {
  padding-top: 10px;
}
