.salesAndCostItems {
  background-color: green;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  margin: 0 5px;
}
.salesAndCostItemsWrapper{
    background-color: white;
    padding: 10px;
    text-align: center;
}
