.totalOrsingleSales {
  background-color: green;
  color: white;
  border: none;
  padding: 10px;
  text-decoration: none;
  margin: 10px;
  border-radius: 10px;
}
.activeClas {
  background-color: blue;
}
