#formOnAddTransaction div {
  display: flex;
  flex-direction: column;
}
#formOnAddTransaction input {
  padding: min(2vw, 10px);
  margin: 0 auto;
  margin-top: min(2vw, 10px);
  width: 80%;
}
#formOnAddTransaction {
  background-color: white;
  width: 90vw;
  max-width: 300px;
  margin: auto;
  padding: 1vw;
}
.productName-transaction {
  width: fit-content;
  margin: auto;
}
.RegisterSales {
  display: block;
  margin: auto;
  padding: 10px;
  border: blue;
  background-color: blue;
  color: white;
  border-radius: 10px;
  margin-top: 10px;
}
.addTotalSalesWrapper {
  margin-top: 20px;
}
