.singleSalesWrapper {
  padding-top: 50px;
}
.singleSalesWrapper input {
  margin: 10px;
  padding: 10px;
}
.singleSalesWrapper button {
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 10px;
  border: none;
}
.singleTransactionForm {
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.singleTransactionForm input,
.singleTransactionForm button {
  display: none;
}

.getProducts {
  background-color: green;
  color: white;
  padding: 10px;
  width: fit-content;
  margin: 10px auto;
  border-radius: 10px;
}
.getOrRegisterProducts {
  display: flex;
}
